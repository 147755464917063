import Vue from 'vue';
import VueRouter from 'vue-router';
import VueHead from 'vue-head';

Vue.use(VueHead);
Vue.use(VueRouter);

export const dynamicHead = {
  data() {
    return {
      metaParams: {
        title: '',
        description: '',
        image: '',
        schema: null
      },
      exportData: [],
      list: []
    };
  },
  head: function () {
    return {
      title: () => {
        return {
          inner:
            this.metaParams.title && this.metaParams.title.length
              ? this._unescapeHtml(this.metaParams.title)
              : 'Renew – Профессиональная косметика для салонного ухода – Компания”Ренью – Украина” это официальное представительство израильской профессиональной космецевтики Renew для салонного ухода.',
          separator: ' ',
          complement: ' '
        };
      },
      meta: () => {
        let { fullPath } = this.$route;
        let { title, description, image, schema } = this.metaParams;
        let locale = fullPath.slice(1, 4) === 'ua/' ? 'uk_UA' : 'ru_RU';
        image = image ? image : process.env.VUE_APP_API + '/storage/favicon.png';
        // console.log(' this.meta --', this.metaParams);

        let metaCollection = [
          { p: 'og:image', content: image, id: 'og:image' },
          { p: 'og:url', content: window.location.origin + this.$route.path, id: 'og:url' },
          { p: 'og:locale', content: locale, id: 'og:locale' },
          { p: 'twitter:image', content: image, id: 'twitter:image' }
        ];

        console.log('title ===', title);
        if (title) {
          metaCollection = [
            ...metaCollection,
            { p: 'og:title', content: title, id: 'og:title' },
            { p: 'twitter:title', content: title, id: 'twitter:title' },
            { n: 'title', content: title, id: 'title' }
          ];
        }

        if (description) {
          metaCollection = [
            ...metaCollection,
            { p: 'og:description', content: description, id: 'og:description' },
            { p: 'twitter:description', content: description, id: 'twitter:description' },
            { n: 'description', content: description, id: 'description' }
          ];
        }

        return metaCollection;
      },
      link: () => {
        let defaultPath = this.$route.path;
        let canonicalHref = window.location.origin + this.$route.path;
        let shortlinkHref = canonicalHref;
        if (defaultPath.slice(1, 4) === 'ua/') {
          defaultPath = defaultPath.slice(3);
        }
        console.log('link - ', window.location, this.$route, defaultPath);
        return [
          { rel: 'canonical', href: canonicalHref, id: 'canonical' },
          { rel: 'shortlink', href: shortlinkHref, id: 'shortlink' },
          { rel: 'alternate', hreflang: 'x-default', href: window.location.origin + defaultPath, id: 'x-default' },
          { rel: 'alternate', hreflang: 'ru-ru', href: window.location.origin + defaultPath, id: 'ru' },
          { rel: 'alternate', hreflang: 'uk-ua', href: window.location.origin + '/ua' + defaultPath, id: 'ua' }
        ];
      },
      script: () => {
        let org = {
          '@context': 'http://schema.org',
          '@type': 'Organization',
          name: 'Renew',
          url: 'https://renew.net.ua/',
          logo: 'https://renew.net.ua/logo.png',
          // foundingDate: '2015',
          contactPoint: [
            { '@type': 'ContactPoint', email: 'renew.ukraine@gmail.com', contactType: 'Sales' },
            {
              '@type': 'ContactPoint',
              telephone: '+38(095) 011 30 70',
              contactType: 'Customer Support'
            },
            {
              '@type': 'ContactPoint',
              telephone: '+38(067) 411 30 70',
              contactType: 'Customer Support'
            }
          ],
          sameAs: []
        };
        this.exportData.push({ id: 'organization', t: 'application/ld+json', i: JSON.stringify(org) });
        return this.exportData;
      }
    };
  },
  watch: {
    $route() {
      this.setMeta();
    }
    // product: {
    //   immediate: true,
    //   handler(newVal) {
    //     if (newVal) {
    //       this.setMeta();
    //     }
    //   }
    // }
  },
  methods: {
    _unescapeHtml(data) {
      const textArea = document.createElement('textarea');
      textArea.innerHTML = data;
      return textArea.value;
    },
    setMeta() {
      this.$emit('updateHead');
    },
    setCatalogSchema() {
      this.exportData.push({ id: 'ItemList', t: 'application/ld+json', i: this.metaParams.schema });
      this.$emit('updateHead');
    },
    setProductSchema() {
      this.exportData.push({ id: 'Product', t: 'application/ld+json', i: this.metaParams.schema });
      this.$emit('updateHead');
    }
  }
};
