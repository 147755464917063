import { mapGetters } from 'vuex';

export default {
  name: 'BreadcrumbsSimple',
  props: {
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      breadcrumbs: [],
      callbacks: {}
    };
  },
  computed: {
    ...mapGetters({
      translations: 'setting/translations'
    }),
    breadcrumbsList() {
      let main = {
        title: this.$t('home'),
        route: { name: 'home' }
      };
      return this.list.length ? [main, ...this.list, { title: this.title }] : [main, { title: this.title }];
    }
  },
  created() {},
  methods: {}
};
