import { mapActions, mapGetters } from 'vuex';

//---helpers
import { globalLoader } from '@/helpers/variables';

//---mixins
import { dynamicHead } from '@/mixins/dynamic-head.js';

//---components
import BreadcrumbsSimple from '@/components/@general/BreadcrumbsSimple/index.vue';

export default {
  name: 'Contacts',
  mixins: [dynamicHead],
  components: { BreadcrumbsSimple },
  props: {},
  data() {
    return {
      contactsData: null
    };
  },
  created() {
    this._loadContent();
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapGetters({
      metaData: 'meta/metaData'
    })
  },
  methods: {
    ...mapActions({
      getContacts: 'pages/GET_CONTACTS',
      getMetaData: 'meta/GET_META_DATA'
    }),
    async _loadContent() {
      globalLoader(true);
      let promiseCollection = [];

      promiseCollection.push(
        this.getContacts().then((result) => {
          this.contactsData = result;
        })
      );

      promiseCollection.push(
        this.getMetaData('contact').then(() => {
          this._setMetaParams();
        })
      );
      await Promise.all(promiseCollection);
      globalLoader(false);
    },
    _setMetaParams() {
      let { metaTitle, metaDescription, image } = this.metaData;
      if (metaTitle) this.metaParams.title = `${metaTitle}${this.$t('seo.metaTitleGeneral')}`;

      if (metaDescription) this.metaParams.description = metaDescription;
      if (image) this.metaParams.image = image;
      this.setMeta();
    }
  }
};
